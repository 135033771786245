export const navbarConfig = [
  {
    text: "规范",
    items: [
      {
        text: "风格指南",
        link: "/docs/guide/style-guide/"
      },
      {
        text: "Git 规范",
        link: "/docs/guide/git-standard/"
      },
      {
        text: "项目格式规范",
        link: "/docs/guide/format/"
      }
    ]
  },
  {
    text: "指南",
    items: [
      {
        text: "开发手册",
        items: [
          {
            text: "微前端使用手册",
            link: "/docs/tutorial/develop/micro-frontends.md"
          },
          {
            text: "App 开发指南",
            link: "/docs/tutorial/develop/app.md"
          },
          {
            text: "小程序开发指南",
            link: "/docs/tutorial/develop/mini-program.md"
          },
          {
            text: "企业微信开发指南",
            link: "/docs/tutorial/develop/wecom.md"
          },
          {
            text: "浏览器扩展插件开发指南",
            link: "/docs/tutorial/develop/chrome-extension.md"
          }
        ]
      },
      {
        text: "部署手册",
        items: [
          {
            text: "Horizon 部署手册",
            link: "/docs/tutorial/deploy/horizon.md"
          },
          { text: "App 发布手册", link: "/docs/tutorial/deploy/app.md" },
          { text: "H5 部署手册", link: "/docs/tutorial/deploy/h5.md" },
          {
            text: "小程序发布手册",
            link: "/docs/tutorial/deploy/mini-program.md"
          },
          {
            text: "Jenkins 部署手册",
            link: "/docs/tutorial/jenkins/jenkins.md"
          }
        ]
      },
      {
        text: "技术分享",
        items: [
          {
            text: "AG Grid 手册",
            link: "/docs/tutorial/sharing/ag-grid/README.md"
          },
          { text: "兼容性问题", link: "/docs/tutorial/sharing/compatible/pc" },
          {
            text: "qiankun 调用地图",
            link: "/docs/tutorial/sharing/qiankun/map.md"
          },
          {
            text: "semantic-release 自动发布版本工具",
            link: "/docs/tutorial/sharing/git/semantic-release.md"
          },
          {
            text: "@cndinfo/cube-cli 打包工具",
            link: "/docs/tutorial/sharing/cube-packaging-tool/README.md"
          },
          {
            text: "手机号码一键登录",
            link: "/docs/tutorial/sharing/mobile-sso"
          },
          {
            text: "国际化 webpack插件",
            link: "/docs/tutorial/sharing/i18n-loader-vue2"
          }
        ]
      }
    ]
  },
  {
    text: "生态",
    items: [
      {
        text: "AMS (通用APP管理平台)",
        items: [
          {
            text: "后台入口",
            link: "https://ams.cube.cndinfo.com/"
          },
          {
            text: "操作手册",
            link: "/docs/ecosystem/ams/manual.md"
          },
          {
            text: "API",
            link: "/docs/ecosystem/ams/api.md"
          },
          {
            text: "SDK",
            link: "/docs/ecosystem/ams/sdk.md"
          },
          {
            text: "文件预览",
            link: "/docs/ecosystem/ams/preview"
          }
        ]
      },
      {
        text: "组件库",
        items: [
          {
            text: "PC组件库",
            link: "https://web.fed.cndinfo.com/"
          }
        ]
      },
      {
        text: "用户行为分析",
        items: [
          {
            text: "埋点设计",
            link: "/docs/ecosystem/analytics/manual"
          },
          {
            text: "火山引擎",
            link: "/docs/ecosystem/analytics/volcengine"
          },
          {
            text: "SDK",
            link: "/docs/ecosystem/analytics/sdk"
          },
          {
            text: "分析列表",
            link: "/docs/ecosystem/analytics/events"
          }
        ]
      },
      {
        text: "Sentry 异常监控工具",
        items: [
          {
            text: "接入手册",
            link: "/docs/ecosystem/sentry/develop"
          }
        ]
      },
      {
        text: "工具",
        items: [
          {
            text: "脚手架",
            link: "/docs/ecosystem/tools/scaffolding.md"
          },
          {
            text: "VS Code 配置",
            link: "/docs/ecosystem/tools/vscode.md"
          },
          {
            text: "图标生成工具",
            link: "/docs/ecosystem/tools/icons-generator"
          }
        ]
      },
      {
        text: "公共服务",
        items: [
          {
            text: "NPM 私有仓库",
            link: "https://npm.devops.cndinfo.com/"
          },
          {
            text: "Git",
            link: "https://git.cndinfo.com/"
          }
        ]
      }
    ]
  }
]
