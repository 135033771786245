<template>
  <div class="navbar-search">
    <cube-popover
      v-model="resultVisible"
      placement="bottom-end"
      width="500"
      trigger="manual"
    >
      <div v-if="searchResult.length" class="navbar-search__result">
        <div
          v-for="(item, index) in searchResult"
          :key="index"
          class="navbar-search__result__item"
          @click="resultClick(item)"
        >
          <h1>
            {{
              `${item.mdTitle ? item.mdTitle + " --- " : ""}` + item.anchorName
            }}
          </h1>
          <p
            v-html="item.value"
          />
        </div>
      </div>
      <div v-else class="navbar-search__result">未找到相关内容</div>
      <div slot="reference" class="navbar-search__input">
        <cube-input
          v-model="input"
          placeholder="输入关键词搜索"
          @input="searchInput"
          @blur="resultVisible = false"
          @focus="searchFocus"
        />
      </div>
    </cube-popover>
  </div>
</template>

<script>
import Worker from "./search.worker.js"
import frontMatter from "../../../.cube/pagesData.json"
const { vuePagesData, pagesData } = frontMatter
export default {
  name: "NavSearch",
  data() {
    return {
      input: "",
      resultVisible: false,
      searchResult: [],
      debounceTimer: null,
      tagArr: ["p", "ul", "h1", "h2", "h3", "h4", "h5", "h6", "blockquote"],
      hArr: ["h1", "h2", "h3", "h4", "h5", "h6"],
      vueTexts: []
    }
  },
  created() {
    // this.getVueTexts()
    // TODO 具体生产环境可能需要确定一下路径， worker.js 写在public文件夹中
    this.worker = new Worker()

    this.worker.onmessage = (event) => {
      const data = event.data
      if (this.input) {
        this.searchResult = data
        this.resultVisible = true
      } else {
        this.searchResult = []
        this.resultVisible = false
      }
    }
  },
  methods: {
    // 获取vue的索引库
    getVueTexts() {
      vuePagesData.forEach((vueItem) => {
        const html = this.getInner("template", vueItem.texts)
        const div = document.createElement("div") // 创建一个div标签
        div.innerHTML = html // 给div标签赋值
        const textsArr = []
        let anchor = "" // 锚点
        let paraId = 0 // 锚点
        for (let index = 0; index < div.children[0].children.length; index++) {
          const element = div.children[0].children[index]
          // 如果是需要搜索的标签
          if (this.tagArr.includes(element.localName)) {
            // 如果是h标签，需要重置锚点内容
            if (this.hArr.includes(element.localName)) {
              anchor = element.innerText
              paraId = 0
            }
            textsArr.push({
              value: element.innerText.replace("\n", "").replace(/\s*/g, ""),
              paraId: paraId,
              anchorName: anchor,
              mdTitle: "",
              isH1: true
            })
          } else if (element.localName === "markdown") {
            for (
              let childIndex = 0;
              childIndex < element.children.length;
              childIndex++
            ) {
              const item = element.children[childIndex]
              if (this.tagArr.includes(item.localName)) {
                if (this.hArr.includes(item.localName)) {
                  anchor = item.innerText
                  paraId = 0
                }
                textsArr.push({
                  value: item.innerText.replace("\n", "").replace(/\s*/g, ""),
                  paraId: paraId,
                  anchorName: anchor,
                  mdTitle: "",
                  isH1: false
                })
              }
            }
          }
        }
        this.vueTexts.push({
          texts: textsArr,
          path: vueItem.path
        })
      })
    },
    getInner(ele, str) {
      const len = ele.length + 4 // <>\n
      return str.substring(
        str.indexOf("<" + ele + ">") + len,
        str.indexOf("</" + ele + ">")
      )
    },
    searchInput(value) {
      const str = value.trim()
      if (str) {
        clearTimeout(this.debounceTimer)
        const texts = []
        pagesData.forEach((item) => {
          if (item.texts.length) {
            texts.push({
              texts: item.texts,
              path: item.path
            })
          }
        })
        this.debounceTimer = setTimeout(() => {
          // 将数组发送给 worker 排序
          this.worker.postMessage({
            str: str,
            data: [...texts, ...this.vueTexts] // 把md和vue文件需要索引的内容库拼接
          })
        }, 200)
      } else {
        this.resultVisible = false
        this.searchResult = []
      }
    },
    searchFocus() {
      // 获取焦点时，如果输入有值，那么就展示结果面板，没有值就不展示，并且重置结果
      if (this.input) {
        this.resultVisible = true
      } else {
        this.searchResult = []
        this.resultVisible = false
      }
    },
    // 点击跳转对应文案的路由和锚点位置
    resultClick(data) {
      const resultData = JSON.parse(JSON.stringify(data))
      if (!resultData.isH1) {
        resultData.path = `${resultData.path}#${resultData.anchorName}`
      }
      this.resultVisible = false
      resultData.path = resultData.path[0] === "/" ? resultData.path : "/" + resultData.path

      if (resultData.path === this.$route.fullPath) return

      const { name } = this.$route.matched.find(item => item.path === data.path) || {}

      if (name) {
        this.$router.push({
          name,
          params: {
            hash: resultData.anchorName
          }
        })
      } else {
        this.$router.push({
          path: resultData.path
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import './index.scss';
@import '@/assets/style/transitions.scss';
</style>
